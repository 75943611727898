import React from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import { useTranslation, useEnvironment } from 'yoshi-flow-editor-runtime';

import { Plan } from '../Plan';
import { classes } from './PlanList.st.css';
import { getPlanValidityCycle } from '../../../../../utils';
import { NO_BREAK_SPACE } from '../../../../../constants';
import { orderPlansForMobileView } from '../../../../../utils/orderPlansForMobileView';
import { useAdaptedSettings } from '../../../../../hooks';

// Max item width + margins;
export const MAX_ITEM_WIDTH = 454 + 60;

export interface PlanListProps {
  plans: PublicPlan[];
  selectPlan: (plan: PublicPlan) => void;
  showDemoHighlight?: boolean;
}

export const PlanList: React.FC<PlanListProps> = ({ plans, showDemoHighlight, selectPlan }) => {
  const settings = useAdaptedSettings();
  const { t } = useTranslation();
  const { isMobile, isEditorX } = useEnvironment();

  const highlightedPlanId = settings.getHighlightedPlanId(plans, showDemoHighlight);
  let orderedPlans = settings.getOrderedPlans(plans);
  if (isMobile && highlightedPlanId) {
    orderedPlans = orderPlansForMobileView(orderedPlans, highlightedPlanId);
  }

  const recurringPlansExist = orderedPlans.filter((p) => p.paymentOptions?.recurring).length > 0;
  const freeTrialDaysExist = orderedPlans.filter((p) => p.paymentOptions?.freeTrialDays).length > 0;
  const taglinesExist = orderedPlans.filter((p) => p.details?.description).length > 0;
  const validityCyclesExist = orderedPlans.filter((p) => getPlanValidityCycle(p, t) !== NO_BREAK_SPACE).length > 0;
  const anyBenefits = !!orderedPlans.find((p) => p.details?.benefits?.length);

  const maxWidth = MAX_ITEM_WIDTH * orderedPlans.length;

  return (
    <ul className={classes.container} style={isEditorX ? {} : { maxWidth }}>
      {orderedPlans.map((plan) => (
        <li key={plan.id} data-hook="plan">
          <Plan
            anyBenefits={anyBenefits}
            highlighted={highlightedPlanId === plan.id}
            plan={plan}
            recurringPlansExist={recurringPlansExist}
            freeTrialDaysExist={freeTrialDaysExist}
            validityCyclesExist={validityCyclesExist}
            taglinesExist={taglinesExist}
            onClick={() => selectPlan(plan)}
          />
        </li>
      ))}
    </ul>
  );
};
